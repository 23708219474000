import * as React from "react"

export default function FanDuel(props) {
  return (
    <svg width={55} height={54} viewBox="0 0 55 54" {...props}>
      <g fill="#1493FF" fillRule="evenodd">
        <path d="M27.282 53.962c16.165-9.378 27.14-26.86 27.14-46.612 0-.16-.086-.31-.226-.389C47.503 3.154 39.655.647 31.414.001a.473.473 0 00-.411.182A49.356 49.356 0 0025.488 9.2a.16.16 0 00.151.227 45.342 45.342 0 0118.747 3.406C42.82 24.945 36.36 35.54 27.033 42.611a45.244 45.244 0 01-5.335-4.755c-.108-.113-.3-.02-.275.133a48.839 48.839 0 005.466 15.865.287.287 0 00.393.108z" />
        <path d="M18.045 32.727a44.228 44.228 0 01-2.923-4.718 28.4 28.4 0 012.73-1.124.449.449 0 00.301-.395 51.904 51.904 0 011.58-9.71.153.153 0 00-.178-.188 37.766 37.766 0 00-7.984 2.61 43.91 43.91 0 01-1.294-6.335 44.075 44.075 0 0111.6-3.06.415.415 0 00.331-.25C23.621 6.254 25.295 3.3 27.395.23a.146.146 0 00-.12-.229C17.312.047 8.525 2.597.658 7.047a.472.472 0 00-.237.41c0 16.843 8.41 32.751 21.265 42.511.126.095.295-.037.236-.182a51.791 51.791 0 01-3.806-16.853.393.393 0 00-.071-.206z" />
      </g>
    </svg>
  )
}
