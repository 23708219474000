import * as React from "react"

export default function Menzies(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 538.36 538.36"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-1{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M346.12 15c51 0 76.55 0 104 8.69a107.94 107.94 0 0164.53 64.53c8.69 27.47 8.69 53 8.69 104v153.9c0 51 0 76.55-8.69 104a107.94 107.94 0 01-64.53 64.53c-27.47 8.69-53 8.69-104 8.69H192.24c-51 0-76.56 0-104-8.69a108 108 0 01-64.54-64.53c-8.68-27.47-8.68-53-8.68-104V192.24c0-51 0-76.55 8.68-104a108 108 0 0164.52-64.55c27.46-8.69 53-8.69 104-8.69h153.9m0-15H192.24c-52.51 0-78.87 0-108.55 9.38l-.3.1-.3.11a122.65 122.65 0 00-73.5 73.5l-.11.3-.1.3C0 113.37 0 139.73 0 192.24v153.88C0 398.63 0 425 9.38 454.66l.1.31.11.3a122.68 122.68 0 0073.5 73.5l.3.11.3.09c29.68 9.39 56 9.39 108.55 9.39h153.88c52.51 0 78.87 0 108.55-9.39l.3-.09.3-.11a122.68 122.68 0 0073.5-73.5l.11-.3.1-.31c9.38-29.67 9.38-56 9.38-108.54V192.24c0-52.51 0-78.87-9.38-108.55l-.1-.3-.11-.3a122.68 122.68 0 00-73.5-73.5l-.3-.11-.31-.1C425 0 398.63 0 346.12 0z"
          />
          <path
            d="M192.24 15h153.88c51 0 76.55 0 104 8.69a107.94 107.94 0 0164.53 64.53c8.69 27.47 8.69 53 8.69 104v153.9c0 51 0 76.55-8.69 104a107.94 107.94 0 01-64.53 64.53c-27.47 8.69-53 8.69-104 8.69H192.24c-51 0-76.55 0-104-8.69a107.94 107.94 0 01-64.53-64.53c-8.69-27.47-8.69-53-8.69-104V192.24c0-51 0-76.55 8.69-104a107.94 107.94 0 0164.51-64.55c27.47-8.69 53-8.69 104-8.69"
            fill="#203668"
          />
          <path
            className="prefix__cls-1"
            d="M93.62 433.54l62.75-316.27h3.59L269.4 341.43l109.02-224.16h3.38l62.32 316.27h-52.6l-33.38-173.03-86.63 183.39h-4.64l-90.01-183.39-33.38 173.03H93.62z"
          />
        </g>
      </g>
    </svg>
  )
}
